import React from "react"

export default function VideoItems({
  videoList,
  groups,
  setVideo,
  setShowModal,
  showPictures,
}) {
  const ages = groups.map(age => age.node.title)
  const videos = videoList.map(video => ({
    drive_link: video.node.drive_link,
    age_group: video.node.age_group.map(age => age.title),
    id: video.node.id,
    youtube_iframe: video.node.youtube_iframe,
    title: video.node.title,
    thumb: video.node.thumbnail.asset.url,
  }))

  return (
    <>
      {ages.map(age => (
        <div key={age} className=" rounded-md lg:p-10 mt-3">
          <h2 className="text-3xl tracking-tight font-extrabold  text-ascendance sm:text-4xl">
            {age.length > 5 || age === "Teens" ? `${age}` : `Ages ${age}`}
          </h2>
          <div className="mt-8 lg:mt-12  mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {videos
              .filter(video => video.age_group.includes(age))
              .map(video => (
                <div
                  key={`${video.id}_${age}`}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover cursor-pointer"
                      src={video.thumb}
                      alt=""
                      onClick={() => {
                        setVideo(video.youtube_iframe)
                        setShowModal(true)
                      }}
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <a className="block">
                      <p
                        className="text-xl font-semibold text-ascendance cursor-pointer mb-5"
                        onClick={() => {
                          setVideo(video.youtube_iframe)
                          setShowModal(true)
                        }}
                      >
                        {video.title}
                      </p>
                    </a>
                    <div className="flex-1">
                      <div className="text-base font-medium flex flex-col items-start justify-start">
                        <a
                          href={video.drive_link}
                          target="_blank"
                          rel="noopener"
                          className="bg-gray-600 text-white rounded px-2 py-2"
                        >
                          Download Video
                        </a>
                        {showPictures && (
                          <>
                            <a
                              href="https://recital.paperform.co/"
                              target="_blank"
                              rel="noopener"
                              className="bg-gray-600 text-white rounded px-2 py-2 mt-2"
                            >
                              Download Pictures
                            </a>
                            <p className="text-sm mt-1 text-ascendance">
                              Rate our show and get show pictures free
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </>
  )
}
